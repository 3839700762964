<template>
  <div id="custom-modal" class="custom-modal modal-fullscreen">
    <div id="custom-modal-body bg-transparent" class="custom-modal-body shadow">
      <div class="bg-primary text-light text-center p-3 position-relative">
        Select event
        <button
          class="btn py-0 px-4 h-100 position-absolute"
          style="right: 0; top: 0"
          @click="close()"
        >
          <i class="fa fa-times text-light" />
        </button>
      </div>
      <div class="input-group py-2">
        <span class="input-group-text bg-white border-0">
          <i class="fas fa-search" />
        </span>
        <input
          type="text"
          class="form-control border-0 ps-0 no-focus"
          :class="{ 'border-end-0': search.length > 0 }"
          placeholder="Search by title"
          v-model="search"
        />
        <button
          class="btn bg-white border border-light border-0"
          type="button"
          v-if="search.length > 0"
          @click="search = ''"
        >
          &times;
        </button>
      </div>
      <div
        class="
          table-responsive
          rounded
          shadow-sm
          rounded
          border border-light
          position-relative
        "
      >
        <table class="table lh-sm table-admin table-hover w-100">
          <thead>
            <tr>
              <th class="pe-0" style="width: 1%"></th>
              <th>Title</th>
              <th>Date</th>
              <th class="text-center">Price</th>
              <th class="text-center">Status</th>
              <th class="text-center">Links</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="event in computedEvents"
              :key="event._id"
              @click="selectEvent(event)"
              :class="{
                'bg-light cursor-not-allowed semi-transparent':
                  disable && disable.find((d) => d._id == event._id),
              }"
            >
              <td class="pe-0" style="width: 1%">
                <img
                  :src="Helper.formatMediaUrl(event.poster)"
                  style="height: 20px; width: 20px"
                  class="shadow-sm rounded"
                  v-if="Helper.formatMediaUrl(event.poster)"
                />
              </td>
              <td class="text-nowrap text-truncate">
                <small class="d-block text-muted">{{ event.topic }}</small>
                {{ event.title }}
              </td>
              <td class="text-nowrap text-truncate" style="width: 1%">
                <small class="d-block">
                  {{ Helper.formatDate(event.start, true) }}
                </small>
                <small>
                  {{ Helper.formatDate(event.end, true) }}
                </small>
              </td>
              <td
                class="text-nowrap text-truncate text-center"
                style="width: 1%"
              >
                {{ Helper.formatMoney(event.price) }}
              </td>
              <td
                class="text-nowrap text-truncate text-center"
                :class="
                  event.status == 'Published' ? 'text-success' : 'text-muted'
                "
                style="width: 1%"
              >
                {{ event.status }}
              </td>
              <td
                class="text-nowrap text-truncate text-center"
                style="width: 1%"
              >
                <a
                  :href="event.fb_link"
                  target="_blank"
                  class="mx-1"
                  v-if="event.fb_link"
                >
                  <img src="@/assets/icons/facebook.png" style="height: 20px" />
                </a>
                <a
                  :href="event.ig_link"
                  target="_blank"
                  class="mx-1"
                  v-if="event.ig_link"
                >
                  <img
                    src="@/assets/icons/instagram.png"
                    style="height: 20px"
                  />
                </a>
                <a
                  :href="event.onpay_link"
                  target="_blank"
                  class="mx-1"
                  v-if="event.onpay_link"
                >
                  <img src="@/assets/icons/onpay.png" style="height: 20px" />
                </a>
              </td>
            </tr>
            <tr v-if="computedEvents.length < 1">
              <td colspan="6" class="text-center fst-italic text-muted">
                No event found
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <loading-spinner class="position-absolute text-light py-5" v-if="isLoading">
      Loading event list...
    </loading-spinner>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import LoadingSpinner from "@/components/LoadingSpinner";

export default {
  components: { LoadingSpinner },
  props: ["disable"],
  data() {
    return {
      events: [],
      isLoading: false,
      search: "",
    };
  },
  computed: {
    computedEvents() {
      const search = this.search.toLowerCase();

      return this.events.filter((event) =>
        event.title.toLowerCase().includes(search)
      );
    },
  },
  methods: {
    selectEvent(event) {
      if (!this.disable || !this.disable.find((d) => d._id == event._id)) {
        Swal.fire({
          title: "<h5>Select this event?</h5>",
          html: `<div class="alert alert-info mb-0">${event.title}</div>`,
          icon: "question",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.isConfirmed) {
            this.close(event);
          }
        });
      }
    },
    async getEvents() {
      this.isLoading = true;

      try {
        const { data } = await this.API.get(
          "webinars?_limit=-1&status=Published&_sort=start:DESC"
        );

        this.events = data;
      } catch (error) {
      } finally {
        this.isLoading = false;
      }
    },
    close(e) {
      document.body.classList.remove("overflow-hidden");

      this.$emit("close", e);
    },
  },
  mounted() {
    document.body.classList.add("overflow-hidden");

    this.getEvents();
  },
};
</script>