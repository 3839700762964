<template>
  <div class="border rounded shadow-sm bg-white position-relative">
    <div
      class="lead bg-secondary fw-medium mb-4 p-3 text-center rounded position-relative"
      style="
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      "
    >
      <button
        class="btn btn-link"
        :style="{
          position: 'absolute',
          top: '0px',
          left: '10px',
          height: '100%',
        }"
        @click="$emit('close')"
      >
        <i class="fas fa-arrow-left" />
      </button>
      User details
      <button
        class="btn btn-link"
        :style="{
          position: 'absolute',
          top: '0px',
          right: '10px',
          height: '100%',
        }"
        @click="$emit('edit')"
      >
        <i class="fas fa-edit" />
      </button>
    </div>
    <div class="p-3 px-md-5 pb-md-5">
      <div class="row g-5">
        <div class="col-12 col-lg-6">
          <h5 class="mb-0 py-2">Account Type</h5>
          <div class="lead">
            <span class="badge h1 bg-purple-dark" v-if="user.isCRT">
              Tutor
            </span>
            <span class="badge bg-blue-dark" v-else-if="user.isStudent">
              Student
            </span>
            <span class="badge bg-teal-dark" v-else>Other</span>
          </div>

          <div class="btn-group btn-group-sm py-3">
            <button
              class="btn bg-purple-dark text-light px-3"
              @click="convertUser('crt')"
              v-if="!user.isCRT"
            >
              <i class="fas fa-exchange-alt me-2" />
              Set as CRT account
            </button>

            <button
              class="btn bg-blue-dark text-light px-3"
              @click="convertUser('student')"
              v-if="!user.isStudent"
            >
              <i class="fas fa-exchange-alt me-2" />
              Set as Student account
            </button>

            <button
              class="btn bg-teal-dark text-light px-3"
              @click="convertUser()"
              v-if="user.isCRT || user.isStudent"
            >
              <i class="fas fa-exchange-alt me-2" />
              Set as Other account
            </button>
          </div>

          <h5 class="mb-3 py-2">Account Details</h5>
          <div class="mb-3">
            <small class="form-label text-muted">Email address</small>
            <p>{{ user.email || "\&nbsp;" }}</p>
          </div>
          <div>
            <small class="form-label text-muted">Name</small>
            <p>{{ user.name || "\&nbsp;" }}</p>
          </div>
          <div>
            <small class="form-label text-muted">Registration date</small>
            <p>{{ Helper.formatDate(user.createdAt, true) }}</p>
          </div>
          <div>
            <small class="form-label text-muted">Last login on</small>
            <p>{{ Helper.formatDate(user.lastLogin, true) || "-" }}</p>
          </div>
          <div class="py-3">
            <button class="btn bg-purple px-4" @click="resetPassword()">
              <i class="fas fa-sync-alt me-2" />
              Reset user password
            </button>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <h5 class="mb-3 py-2">Contact Details</h5>
          <div class="mb-3">
            <small class="form-label text-muted">Phone</small>
            <p>{{ user.phone || "\&nbsp;" }}</p>
          </div>
          <div class="mb-3">
            <small class="form-label text-muted">Address</small>
            <p class="mb-0">{{ user.address1 || "\&nbsp;" }}</p>
            <p>{{ user.address2 || "\&nbsp;" }}</p>
          </div>
          <div class="row g-3">
            <div class="col-12 col-sm-6">
              <small class="form-label text-muted">Postcode</small>
              <p>{{ user.postcode || "\&nbsp;" }}</p>
            </div>
            <div class="col-12 col-sm-6">
              <small class="form-label text-muted">City</small>
              <p>{{ user.city || "\&nbsp;" }}</p>
            </div>
          </div>
          <div class="row g-3">
            <div class="col-12 col-sm-6">
              <small class="form-label text-muted">State</small>
              <p>{{ user.state || "\&nbsp;" }}</p>
            </div>
            <div class="col-12 col-sm-6">
              <small class="form-label text-muted">Country</small>
              <p>{{ user.country || "\&nbsp;" }}</p>
            </div>
          </div>
        </div>
      </div>

      <hr class="mt-5 mb-4" />

      <template v-if="user">
        <h5 class="mb-3 py-2 d-flex justify-content-between align-items-center">
          <div v-if="user.isStudent">Student Details</div>
          <template v-else>
            <div>
              Dependants
              <span
                class="badge bg-light border text-dark fw-normal py-2 px-3 ms-2"
              >
                {{ dependants ? dependants.length : 0 }}
              </span>
            </div>
            <button
              class="btn btn-sm btn-primary px-3"
              @click="manageDependant()"
            >
              <i class="fas fa-plus me-2" />
              Add dependant
            </button>
          </template>
        </h5>
        <div
          class="table-responsive rounded shadow-sm rounded border border-light mb-4"
        >
          <table class="table lh-sm table-admin w-100">
            <thead>
              <tr>
                <th class="pe-0" style="width: 1%">#</th>
                <th>Name</th>
                <th class="text-center" style="width: 1%">MYKID/NRIC</th>
                <th class="text-center text-nowrap" style="width: 1%">
                  Date of Birth
                </th>
                <th class="text-center" style="width: 1%">Age</th>
                <th class="text-center" style="width: 1%">Classes</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(dependant, index) in dependants"
                :key="dependant._id"
                :ref="`user_${dependant._id}`"
              >
                <td style="width: 1%" class="pe-0">{{ index + 1 }}</td>
                <td class="text-nowrap text-truncate" style="min-width: 300px">
                  <i
                    class="fas fa-circle me-2"
                    :class="
                      dependant.status == 'Active'
                        ? 'text-success'
                        : 'text-danger'
                    "
                  />
                  <button
                    class="btn btn-link p-0 clickable text-decoration-none"
                    @click="manageDependant(dependant)"
                  >
                    {{ dependant.name }}
                  </button>
                </td>
                <td
                  style="width: 1%"
                  class="text-nowrap text-truncate text-center"
                >
                  {{ dependant.id_no }}
                </td>
                <td
                  style="width: 1%"
                  class="text-nowrap text-truncate text-center"
                >
                  {{ Helper.formatDate(dependant.date_of_birth) }}
                </td>
                <td
                  style="width: 1%"
                  class="text-nowrap text-truncate text-center"
                >
                  {{ Helper.getAge(dependant.date_of_birth) }}
                </td>
                <td class="text-center" style="width: 1%">
                  <span class="badge bg-light border text-dark fw-normal p-2">
                    {{ dependant.classes ? dependant.classes.length : 0 }}
                  </span>
                </td>
              </tr>
              <tr v-if="dependants.length < 1">
                <td colspan="6" class="text-center fst-italic text-muted">
                  <button
                    v-if="user.isStudent"
                    class="btn btn-sm btn-primary px-3"
                    @click="linkDependant()"
                  >
                    <i class="fas fa-link me-2" />
                    Link dependant
                  </button>
                  <template v-else>No dependant found</template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <h5 class="mb-3 py-2 d-flex justify-content-between align-items-center">
          <div>
            User's Events
            <span
              class="badge bg-light border text-dark fw-normal py-2 px-3 ms-2"
            >
              {{ user.webinars ? user.webinars.length : 0 }}
            </span>
          </div>
          <button
            class="btn btn-sm btn-primary px-3"
            @click="showSelectEvent = true"
          >
            <i class="fas fa-plus me-2" />
            Add event
          </button>
        </h5>
        <div
          class="table-responsive rounded shadow-sm rounded border border-light"
        >
          <table class="table lh-sm table-admin w-100">
            <thead>
              <tr>
                <th class="pe-0">#</th>
                <th></th>
                <th>Title</th>
                <th class="text-center">Price</th>
                <th>Date</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(event, index) in userEvents"
                :key="event._id"
                :ref="`event_${event._id}`"
              >
                <td style="width: 1%" class="pe-0">{{ index + 1 }}</td>
                <td class="pe-0" style="width: 1%">
                  <img
                    :src="Helper.formatMediaUrl(event.poster)"
                    style="height: 20px; width: 20px"
                    class="shadow-sm rounded clickable"
                    v-if="Helper.formatMediaUrl(event.poster)"
                    @click.stop="currentMedia = event.poster"
                  />
                </td>
                <td class="text-nowrap text-truncate">
                  <small class="d-block text-muted">{{ event.topic }}</small>
                  {{ event.title }}
                </td>
                <td class="text-nowrap text-truncate text-center">
                  {{ Helper.formatMoney(event.price) }}
                </td>
                <td class="text-nowrap text-truncate">
                  <small class="d-block">
                    {{ Helper.formatDate(event.start, true) }}
                  </small>
                  <small>
                    {{ Helper.formatDate(event.end, true) }}
                  </small>
                </td>
                <td class="text-nowrap text-center ps-0" style="width: 1%">
                  <button class="btn btn-sm" @click.stop="removeEvent(event)">
                    <i class="fas fa-minus-circle small text-danger" />
                  </button>
                </td>
              </tr>
              <tr v-if="userEvents.length < 1">
                <td colspan="6" class="text-center fst-italic text-muted">
                  No event booked
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
    </div>
    <media-viewer
      v-if="currentMedia"
      :url="this.Helper.formatMediaUrl(currentMedia)"
      @close="currentMedia = null"
    />
    <loading-spinner class="position-absolute bg-light py-5" v-if="isLoading">
      Loading...
    </loading-spinner>
    <select-event
      @close="closeSelectEvent"
      v-if="showSelectEvent"
      :disable="userEvents"
    />
    <manage-dependant-form
      v-if="showDependantModal"
      :dependant="currentDependant"
      :user="user"
      @close="closeDependantModal"
      @update="closeDependantModal($event, 'edit')"
      @updateClass="closeDependantModal($event, 'edit', true)"
      @delete="closeDependantModal($event, 'delete')"
      @add="closeDependantModal($event, 'add')"
    />
    <select-student @close="closeSelectStudent" v-if="showSelectStudent" />
  </div>
</template>

<script>
import Swal from "sweetalert2";
import LoadingSpinner from "@/components/LoadingSpinner";
import MediaViewer from "@/components/Modals/MediaViewer";
import SelectEvent from "@/components/Modals/SelectEvent";
import ManageDependantForm from "@/components/Modals/ManageDependantForm";
import SelectStudent from "@/components/Modals/SelectStudent";

export default {
  props: ["user"],
  components: {
    LoadingSpinner,
    MediaViewer,
    SelectEvent,
    SelectStudent,
    ManageDependantForm,
  },
  data() {
    return {
      isLoading: false,
      dependants: [],
      currentDependant: null,
      showDependantModal: false,
      currentMedia: null,
      userEvents: [],
      showSelectEvent: false,
      showSelectStudent: false,
    };
  },
  methods: {
    linkDependant() {
      Swal.fire({
        html: "<h5 class='mb-0'>Select link type</h5>",
        icon: "question",
        showCloseButton: true,
        showDenyButton: true,
        denyButtonText: "Create new",
        confirmButtonText: "Link existing",
        denyButtonColor: "teal",
      }).then((result) => {
        if (result.isConfirmed) {
          // Link existing
          this.showSelectStudent = true;
        } else if (result.isDenied) {
          // Create new
          this.manageDependant();
        }
      });
    },
    async closeSelectStudent(e) {
      this.showSelectStudent = false;

      if (e) {
        this.isLoading = true;

        try {
          const { data } = await this.API.put(`dependants/${e._id}`, {
            account: this.user._id,
          });

          this.dependants = [data];

          Swal.fire("Account linked successfully", "", "success");
        } catch (error) {
          Swal.fire(
            "Error updating account type",
            err.response.data.message[0].messages[0].message,
            "error"
          );
        } finally {
          this.isLoading = false;
        }
      }
    },
    convertUser(type) {
      Swal.fire({
        title: `<h5 class='mb-0'>Change account type to <span class='fw-bold text-primary text-uppercase'>${
          type || "other"
        }</span>?</h5>`,
        icon: "question",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            let body = {
              isCRT: type == "crt" ? true : false,
              isStudent: type == "student" ? true : false,
            };

            await this.API.put(`users/${this.user._id}`, body);

            this.$emit(
              "update",
              { ...body, _id: this.user._id },
              "account-type"
            );

            Swal.fire("Account type updated successfully", "", "success");
          } catch (err) {
            Swal.fire(
              "Error updating account type",
              err.response.data.message[0].messages[0].message,
              "error"
            );
          }
        }
      });
    },
    closeDependantModal(dependant, type, dontClose) {
      if (dependant) {
        if (type == "add") {
          this.dependants.push(dependant);

          this.$emit("update", dependant, "dependant");
        } else {
          const dependantIndex = this.dependants.findIndex(
            (d) => d._id == dependant._id
          );

          if (dependantIndex > -1) {
            if (type == "delete") {
              this.dependants = this.dependants.filter(
                (d) => d._id != dependant._id
              );

              this.$emit("update", dependant, "dependant-delete");
            } else {
              this.$set(this.dependants, dependantIndex, dependant);

              this.$emit("update", dependant, "dependant");
            }
          }
        }
      }

      if (!dontClose) {
        this.currentDependant = null;
        this.showDependantModal = false;
      }
    },
    async closeSelectEvent(e) {
      this.showSelectEvent = false;

      if (e) {
        this.isLoading = true;

        try {
          this.userEvents.push(e);

          const { data } = await this.API.put(`users/${this.user._id}`, {
            events: this.userEvents,
          });

          this.$emit("update", data);
        } catch (error) {
        } finally {
          this.isLoading = false;
        }
      }
    },
    async removeEvent(event) {
      Swal.fire({
        title: "<h5 class='mb-0'>Remove this event from user's booking?</h5>",
        icon: "question",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let userEvents = this.userEvents.filter((w) => w._id != event._id);

          this.isLoading = true;

          try {
            const { data } = await this.API.put(`users/${this.user._id}`, {
              events: userEvents,
            });

            this.userEvents = userEvents;

            this.$emit("update", data);
          } catch (error) {
          } finally {
            this.isLoading = false;
          }
        }
      });
    },
    resetPassword() {
      Swal.fire({
        title: "<h5 class='mb-0'>Reset user password?</h5>",
        icon: "question",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            let body = {
              password: this.Helper.defaultPassword,
            };

            await this.API.put(`users/${this.user._id}`, body);

            let title = "Password reset successful";

            let html = `<p>Copy password below and share it with the user</p>
							<h3 class="mb-3">
								<span id="generatedPassword" class="badge bg-teal-dark fw-normal px-4">
									${body.password}
								</span>
							</h3>
							<p class="mb-0">Please remind user to change their password upon first login</p>
						`;

            Swal.fire(title, html, "success");
          } catch (err) {
            Swal.fire(
              "Opps. An error occured",
              err.response.data.message[0].messages[0].message,
              "error"
            );
          }
        }
      });
    },
    manageDependant(dependant) {
      this.currentDependant = dependant;

      this.showDependantModal = true;
    },
    async getDependants() {
      this.isLoading = true;

      return new Promise(async (resolve) => {
        try {
          const relationType = this.user.isStudent ? "account" : "user";

          const { data } = await this.API.get(
            `dependants?${relationType}=${this.user._id}&status_in=Active&status_in=Inactive`
          );

          this.dependants = data;
        } catch (error) {
          console.error(error);
        } finally {
          this.isLoading = false;

          resolve();
        }
      });
    },
  },
  mounted() {
    this.getDependants();

    this.userEvents = this.user.webinars;
  },
};
</script>