<template>
  <loading-spinner class="position-relative bg-light py-5" v-if="isLoading">
    Loading user list
  </loading-spinner>
  <add-user-form
    :user="currentUser"
    @close="closeAddUserForm"
    v-else-if="showAddUserForm"
  />
  <user-details
    :user="currentUser"
    @close="closeUserDetails"
    @update="updateUserDetails"
    @edit="editUser(currentUser)"
    v-else-if="showUserDetails"
  />
  <div v-else>
    <div class="pb-3 d-flex justify-content-between align-items-center">
      <div class="input-group">
        <span class="input-group-text bg-white">
          <i class="fas fa-search" />
        </span>
        <input
          type="text"
          class="form-control border-start-0 ps-0 no-focus"
          :class="{ 'border-end-0': search.length > 0 }"
          placeholder="Search user by name, email or phone"
          v-model="search"
        />
        <button
          class="btn bg-white border border-light"
          type="button"
          v-if="search.length > 0"
          @click="search = ''"
        >
          &times;
        </button>
      </div>
      <div class="ms-2" style="width: 10rem">
        <select class="form-select" v-model="filterType">
          <option :value="null">All Type</option>
          <option value="isStudent">Student</option>
          <option value="isCRT">Tutor</option>
          <option value="others">Others</option>
        </select>
      </div>
    </div>
    <div class="pb-3 d-flex justify-content-between align-items-center">
      <span class="badge bg-dark py-2 fw-normal">
        Total Users: {{ users.length }}
      </span>
      <button class="btn btn-sm btn-primary px-3" @click="editUser()">
        <i class="fas fa-plus me-2" />
        Create user
      </button>
    </div>
    <div class="table-responsive rounded shadow-sm rounded border border-light">
      <table class="table lh-sm table-admin w-100">
        <thead>
          <tr>
            <th class="pe-0">#</th>
            <th>Email</th>
            <th>Name</th>
            <th>Phone</th>
            <th class="text-center">Events</th>
            <th class="text-center">Dependants</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(user, index) in computedUsers"
            :key="user._id"
            :ref="`user_${user._id}`"
          >
            <td style="width: 1%" class="pe-0">{{ index + 1 }}</td>
            <td class="text-nowrap text-truncate">
              <button
                class="btn btn-link p-0 w-100 clickable text-start text-decoration-none text-truncate"
                @click="viewUser(user)"
              >
                <span class="badge bg-purple-dark" v-if="user.isCRT">
                  Tutor
                </span>
                <span class="badge bg-blue-dark" v-else-if="user.isStudent">
                  Student
                </span>
                <p class="mb-0">
                  {{ user.email }}
                </p>
              </button>
            </td>
            <td class="text-nowrap text-truncate">
              {{ user.name }}
            </td>
            <td class="text-nowrap text-truncate">
              {{ user.phone }}
            </td>
            <td class="text-center">
              <span class="badge bg-light border text-dark fw-normal p-2">
                {{ user.webinars ? user.webinars.length : 0 }}
              </span>
            </td>
            <td class="text-center">
              <span
                class="badge bg-light border text-dark fw-normal p-2"
                v-if="!user.isCRT && !user.isStudent"
              >
                {{
                  dependants.filter(
                    (dependant) =>
                      dependant.user && dependant.user._id == user._id
                  ).length
                }}
              </span>
            </td>
            <td class="text-nowrap text-center">
              <button class="btn btn-sm" @click.stop="editUser(user)">
                <i class="fas fa-edit small" />
              </button>
            </td>
          </tr>
          <tr v-if="computedUsers.length < 1">
            <td colspan="7" class="text-center fst-italic text-muted">
              No user found
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/LoadingSpinner";
import AddUserForm from "@/components/AddUserForm";
import UserDetails from "@/components/UserDetails";

export default {
  components: { LoadingSpinner, AddUserForm, UserDetails },
  data() {
    return {
      isLoading: false,
      showAddUserForm: false,
      showUserDetails: false,
      users: [],
      dependants: [],
      search: "",
      currentUser: null,
      filterType: null,
    };
  },
  computed: {
    computedUsers() {
      const search = this.search.toLowerCase();

      let result = [...this.users];

      if (this.filterType) {
        result = result.filter((user) =>
          this.filterType == "others"
            ? !user.isStudent && !user.isCRT
            : user[this.filterType]
        );
      }

      return result.filter(
        (user) =>
          (user.name && user.name.toLowerCase().includes(search)) ||
          user.email.toLowerCase().includes(search) ||
          (user.phone && user.phone.includes(search))
      );
    },
  },
  methods: {
    viewUser(user) {
      this.currentUser = user;

      this.showUserDetails = true;

      window.scrollTo(0, 0);
    },
    closeUserDetails() {
      this.showUserDetails = false;

      if (this.currentUser) {
        this.$nextTick(() => {
          let userRow = this.$refs[`user_${this.currentUser._id}`];

          if (userRow.length > 0) {
            window.scrollTo(0, userRow[0].getBoundingClientRect().top - 150);
          }
        });
      } else {
        window.scrollTo(0, 0);
      }
    },
    updateUserDetails(user, type) {
      if (user && !type) {
        this.currentUser = user;

        let userIndex = this.users.findIndex((u) => u._id == user._id);

        if (userIndex > -1) {
          this.$set(this.users, userIndex, user);
        }
      } else if (type == "dependant-delete") {
        this.dependants = this.dependants.filter(
          (dependant) => dependant._id != user._id
        );
      } else if (type == "dependant") {
        let dependantIndex = this.dependants.findIndex(
          (d) => d._id == user._id
        );

        if (dependantIndex > -1) {
          this.$set(this.dependants, dependantIndex, user);
        } else {
          this.dependants.push(user);
        }
      } else if (type == "account-type") {
        const currentUser = this.users.find((u) => u._id == user._id);

        const userIndex = this.users.findIndex((u) => u._id == user._id);

        if (userIndex > -1) {
          currentUser.isCRT = user.isCRT;

          currentUser.isStudent = user.isStudent;

          this.$set(this.users, userIndex, currentUser);
        }
      }
    },
    editUser(user) {
      this.currentUser = user;

      this.showAddUserForm = true;

      window.scrollTo(0, 0);
    },
    closeAddUserForm(user, type) {
      this.showAddUserForm = false;

      if (this.showUserDetails && user) {
        this.currentUser = user;
      }

      if (type == "edit") {
        let userIndex = this.users.findIndex((u) => u._id == user._id);

        if (userIndex > -1) {
          this.$set(this.users, userIndex, user);
        }
      } else if (user) {
        this.users.unshift(user);
      }
    },
    getUsers() {
      this.isLoading = true;

      this.API.get(
        "users?_limit=-1&role.name=Authenticated&blocked_ne=true&name_ne=DEMO ACCOUNT"
      )
        .then((retVal) => {
          this.users = retVal.data;
        })
        .catch((err) => {})
        .finally(async () => {
          await this.getDependants();

          this.isLoading = false;
        });
    },
    async getDependants() {
      return new Promise(async (resolve) => {
        try {
          const { data } = await this.API.get(
            `dependants?_limit=-1&status_in=Active&status_in=Inactive`
          );

          this.dependants = data;
        } catch (error) {
          console.error(error);
        } finally {
          resolve();
        }
      });
    },
  },
  mounted() {
    this.getUsers();
  },
};
</script>