<template>
  <div id="custom-modal" class="custom-modal modal-fullscreen">
    <div
      id="custom-modal-body"
      class="custom-modal-body shadow position-relative"
    >
      <div
        class="p-3 bg-primary lead text-center fw-medium text-light position-relative"
      >
        <button
          class="btn py-0 px-4 h-100 position-absolute"
          style="left: 0; top: 0"
          @click="deleteDependant()"
          v-if="dependant"
        >
          <i class="fa fa-trash-alt text-danger" />
        </button>
        {{ dependant ? "Edit" : "Add" }}
        {{ user && user.isStudent ? "student" : "dependant" }}
        <button
          class="btn py-0 px-4 h-100 position-absolute"
          style="right: 0; top: 0"
          @click="closePreview()"
        >
          <i class="fa fa-times text-light" />
        </button>
      </div>
      <div class="p-4">
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="mb-3">
              <label for="name" class="form-label">
                Name<sup class="text-danger">*</sup>
              </label>
              <input
                type="text"
                class="form-control"
                id="name"
                v-model="models.name"
              />
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-3">
            <div class="mb-3">
              <label for="id_no" class="form-label">
                MYKID/NRIC<sup class="text-danger">*</sup>
              </label>
              <input
                type="text"
                class="form-control"
                id="id_no"
                maxlength="12"
                v-model="models.id_no"
                @input="models.id_no = models.id_no.replace(/[^\d,]/g, '')"
              />
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-3">
            <div class="mb-3">
              <label for="date_of_birth" class="form-label">
                Date of Birth<sup class="text-danger">*</sup>
              </label>
              <div
                class="input-group cursor-pointer"
                @click="
                  showDatePicker = {
                    for: 'date_of_birth',
                    date: models.date_of_birth,
                    title: 'Select birth date',
                  }
                "
              >
                <div class="input-group-text">
                  <i class="fas fa-calendar-alt" />
                </div>
                <div class="form-control">
                  {{ Helper.formatDate(models.date_of_birth) || "\&nbsp;" }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-3">
            <div class="mb-3">
              <label for="status" class="form-label">
                Status<sup class="text-danger">*</sup>
              </label>
              <select class="form-select" v-model="models.status">
                <option :value="null" disabled>-- Select status</option>
                <option
                  :value="status"
                  v-for="status in statuses"
                  :key="status"
                >
                  {{ status }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <hr class="my-4" />
        <div class="d-flex justify-content-between align-items-center">
          <button class="btn btn-light shadow" @click="closePreview()">
            Cancel
          </button>
          <button class="btn btn-primary" @click="submitChange()">
            <i class="fas fa-save me-2" />
            Submit
          </button>
        </div>
        <template v-if="dependant">
          <hr class="my-4" />
          <div
            class="mb-3 py-2 d-flex justify-content-between align-items-center"
          >
            <h5 class="mb-0">
              Joined Classes
              <span
                class="badge bg-light border text-dark fw-normal py-2 px-3 ms-2"
              >
                {{ dependantClasses.length }}
              </span>
            </h5>
            <button
              class="btn btn-sm btn-primary px-3"
              @click="showSelectClass = true"
            >
              <i class="fas fa-plus me-2" />
              Add class
            </button>
          </div>
          <div
            class="table-responsive rounded shadow-sm rounded border border-light"
          >
            <table class="table lh-sm table-admin w-100">
              <thead>
                <tr>
                  <th class="pe-0">#</th>
                  <th></th>
                  <th>Name</th>
                  <th class="text-center" style="width: 1%">Level</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(cls, index) in dependantClasses"
                  :key="cls._id"
                  :ref="`class_${cls._id}`"
                >
                  <td style="width: 1%" class="pe-0">{{ index + 1 }}</td>
                  <td class="pe-0" style="width: 1%">
                    <img
                      :src="Helper.formatMediaUrl(cls.poster)"
                      style="height: 20px; width: 20px"
                      class="shadow-sm rounded clickable"
                      v-if="Helper.formatMediaUrl(cls.poster)"
                      @click.stop="currentMedia = cls.poster"
                    />
                  </td>
                  <td class="text-nowrap text-truncate">
                    {{ Helper.removeBreakLine(cls.name) }}
                  </td>
                  <td
                    class="text-nowrap text-truncate text-center"
                    style="width: 1%"
                  >
                    {{ cls.level }}
                  </td>
                  <td class="text-nowrap text-center ps-0" style="width: 1%">
                    <button class="btn btn-sm" @click.stop="removeClass(cls)">
                      <i class="fas fa-minus-circle small text-danger" />
                    </button>
                  </td>
                </tr>
                <tr v-if="dependantClasses.length < 1">
                  <td colspan="6" class="text-center fst-italic text-muted">
                    No class joined
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>
      </div>
      <loading-spinner class="text-light position-absolute" v-if="isLoading" />
      <select-class
        @close="closeSelectClass"
        v-if="showSelectClass"
        :disable="dependantClasses"
      />
    </div>
    <media-viewer
      v-if="currentMedia"
      :url="this.Helper.formatMediaUrl(currentMedia)"
      @close="currentMedia = null"
    />
    <date-picker
      v-if="showDatePicker.for"
      :date="showDatePicker.date"
      :title="showDatePicker.title"
      :noTime="true"
      @changed="closeDatePicker"
      @cancelled="closeDatePicker()"
    />
  </div>
</template>

<script>
import Swal from "sweetalert2";
import LoadingSpinner from "@/components/LoadingSpinner";
import DatePicker from "@/components/Modals/DatePicker";
import SelectClass from "@/components/Modals/SelectClass";

export default {
  props: ["dependant", "user"],
  components: {
    LoadingSpinner,
    DatePicker,
    SelectClass,
  },
  data() {
    return {
      isLoading: false,
      currentMedia: null,
      showSelectClass: false,
      dependantClasses: [],
      showDatePicker: {
        for: null,
        date: null,
        title: null,
      },
      statuses: ["Active", "Inactive"],
      models: {
        name: null,
        id_no: null,
        date_of_birth: null,
        status: null,
      },
    };
  },
  computed: {
    formInvalid() {
      if (
        !this.models.name ||
        !this.models.id_no ||
        this.models.id_no < 12 ||
        !this.models.date_of_birth ||
        !this.models.status
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    async closeSelectClass(e) {
      this.showSelectClass = false;

      if (e) {
        this.isLoading = true;

        try {
          this.dependantClasses.push(e);

          await this.API.put(`dependants/${this.dependant._id}`, {
            classes: this.dependantClasses,
          });
        } catch (error) {
        } finally {
          this.isLoading = false;
        }
      }
    },
    removeClass(cls) {
      Swal.fire({
        title:
          "<h5 class='mb-0'>Remove this class from dependant's joined list?</h5>",
        icon: "question",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let dependantClasses = this.dependantClasses.filter(
            (c) => c._id != cls._id
          );

          this.isLoading = true;

          try {
            await this.API.put(`dependants/${this.dependant._id}`, {
              classes: dependantClasses,
            });

            this.dependantClasses = dependantClasses;

            let dependant = JSON.parse(JSON.stringify(this.dependant));

            dependant.classes = dependantClasses;

            this.$emit("updateClass", dependant);
          } catch (error) {
            console.error(error);
          } finally {
            this.isLoading = false;
          }
        }
      });
    },
    deleteDependant() {
      Swal.fire({
        title: "<h5 class='mb-0'>Delete this dependant?</h5>",
        icon: "question",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Confirm",
        customClass: {
          confirmButton: "bg-danger",
        },
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.isLoading = true;

            return this.API.put(`dependants/${this.dependant._id}`, {
              status: "Deleted",
            })
              .catch((error) => {})
              .finally(() => {
                Swal.fire("Dependant deleted", "", "success");

                this.$emit("delete", this.dependant);
              });
          }
        })
        .finally(() => {
          setTimeout(() => {
            this.isLoading = false;
          }, 3000);
        });
    },
    closeDatePicker(date) {
      if (date) {
        this.models[this.showDatePicker.for] = date;
      }

      this.showDatePicker = {
        for: null,
        date: null,
        title: null,
      };
    },
    closePreview(e) {
      this.$nextTick(() => {
        this.$emit("close", e);
      });
    },
    async submitChange() {
      if (this.formInvalid) {
        Swal.fire("", "Invalid form submitted", "error");
      } else {
        this.isLoading = true;

        let method = "post";

        let url = "dependants";

        let body = {};

        try {
          if (this.dependant) {
            method = "put";

            url += `/${this.dependant._id}`;

            body = { ...this.models };
          } else {
            body = { ...this.models };

            if (this.user && this.user.isStudent) {
              body.account = this.user._id;
            } else {
              body.user = this.user._id;
            }
          }

          const { data } = await this.API[method](url, body);

          Swal.fire(
            `${this.user && this.user.isStudent ? "Student" : "Dependant"} ${
              this.dependant ? "updated" : "added"
            }`,
            "",
            "success"
          ).then(() => {
            if (this.dependant) {
              this.closePreview(data);
            } else {
              this.$emit("add", data);
            }
          });
        } catch (err) {
          Swal.fire(
            "Opps. An error occured",
            JSON.stringify(err.response.data),
            "error"
          );
        } finally {
          this.isLoading = false;
        }
      }
    },
  },
  mounted() {
    document.getElementsByTagName("body")[0].classList.add("overflow-hidden");

    if (this.dependant) {
      for (const key in this.models) {
        if (Object.hasOwnProperty.call(this.models, key)) {
          this.models[key] = this.dependant[key];
        }
      }

      this.dependantClasses = this.dependant.classes || [];
    } else {
      this.models.status = "Active";

      if (this.user && this.user.isStudent) {
        this.models.name = this.user.name;
      }
    }
  },
  beforeDestroy() {
    this.closePreview();

    document
      .getElementsByTagName("body")[0]
      .classList.remove("overflow-hidden");
  },
};
</script>