<template>
  <div id="custom-modal" class="custom-modal modal-fullscreen">
    <div id="custom-modal-body bg-transparent" class="custom-modal-body shadow">
      <div class="bg-primary text-light text-center p-3 position-relative">
        Select student
        <button
          class="btn py-0 px-4 h-100 position-absolute"
          style="right: 0; top: 0"
          @click="close()"
        >
          <i class="fa fa-times text-light" />
        </button>
      </div>
      <div class="input-group py-2">
        <span class="input-group-text bg-white border-0">
          <i class="fas fa-search" />
        </span>
        <input
          type="text"
          class="form-control border-0 ps-0 no-focus"
          :class="{ 'border-end-0': search.length > 0 }"
          placeholder="Search by parent, name or nric"
          v-model="search"
        />
        <button
          class="btn bg-white border border-light border-0"
          type="button"
          v-if="search.length > 0"
          @click="search = ''"
        >
          &times;
        </button>
      </div>
      <div
        class="table-responsive rounded shadow-sm rounded border border-light position-relative"
      >
        <table class="table lh-sm table-admin table-hover w-100">
          <thead>
            <tr>
              <th class="pe-0">#</th>
              <th>Name</th>
              <th class="text-center">MYKID/NRIC</th>
              <th class="text-center">Age</th>
              <th>Parent</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(student, index) in computedStudents"
              :key="student._id"
              @click="selectStudent(student)"
              :class="{
                'bg-light cursor-not-allowed semi-transparent':
                  disable && disable.find((d) => d._id == student._id),
              }"
            >
              <td style="width: 1%" class="pe-0">{{ index + 1 }}</td>
              <td class="text-nowrap text-truncate">
                <i
                  class="fas fa-circle me-2"
                  :class="
                    student.status == 'Active' ? 'text-success' : 'text-danger'
                  "
                />
                {{ student.name }}
              </td>
              <td
                class="text-nowrap text-truncate text-center"
                style="width: 1%"
              >
                {{ student.id_no }}
              </td>
              <td
                class="text-nowrap text-truncate text-center"
                style="width: 1%"
              >
                {{ Helper.getAge(student.date_of_birth) }}
              </td>
              <td class="text-nowrap text-truncate">
                <small class="text-muted d-block">
                  {{ student.user ? student.user.email : "\&nbsp;" }}
                </small>
                {{ student.user ? student.user.name : "\&nbsp;" }}
              </td>
            </tr>
            <tr v-if="computedStudents.length < 1">
              <td colspan="5" class="text-center fst-italic text-muted">
                No student found
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <loading-spinner class="position-absolute text-light py-5" v-if="isLoading">
      Loading student list...
    </loading-spinner>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import LoadingSpinner from "@/components/LoadingSpinner";

export default {
  components: { LoadingSpinner },
  props: ["disable"],
  data() {
    return {
      students: [],
      isLoading: false,
      search: "",
    };
  },
  computed: {
    computedStudents() {
      const search = this.search.toLowerCase();

      const _students = [...this.students];

      if (search) {
        _students = this.students.filter(
          (student) =>
            (student.name && student.name.toLowerCase().includes(search)) ||
            (student.user &&
              student.user.email.toLowerCase().includes(search)) ||
            (student.user &&
              student.user.name &&
              student.user.name.toLowerCase().includes(search)) ||
            (student.id_no && student.id_no.includes(search))
        );
      }

      return _students;
    },
  },
  methods: {
    selectStudent(student) {
      if (!this.disable || !this.disable.find((d) => d._id == student._id)) {
        Swal.fire({
          title: "<h5>Select this student?</h5>",
          html: `<div class="alert alert-info mb-0">${student.name}<br /><small>[${student.id_no}]</small></div>`,
          icon: "question",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.isConfirmed) {
            this.close(student);
          }
        });
      }
    },
    async getStudents() {
      this.isLoading = true;

      try {
        const { data } = await this.API.get(
          "dependants?_limit=-1&status_in=Active&status_in=Inactive"
        );

        this.students = data;
      } catch (error) {
      } finally {
        this.isLoading = false;
      }
    },
    close(e) {
      document.body.classList.remove("overflow-hidden");

      this.$emit("close", e);
    },
  },
  mounted() {
    document.body.classList.add("overflow-hidden");

    this.getStudents();
  },
};
</script>